#login-form{
  margin: 10px auto;
width: 100%;
max-width: 400px;
padding: 20px;
border: 1px solid #e6e6e6;
border-radius: 4px;
}

#layout-main .logo {
  padding: 6px 20px;
  background: black;
  text-align: center;
}
#layout-main .logo img {
max-height: 52px;
}

.header{
  background: rgb(255, 255, 255);
padding: 0px;
box-shadow: 0px 0px 20px #e4e4e4;
}

.right-header{
  float: right;
  padding-right: 20px;
}

.right-header .header-logo{
      float: left;
      display: none;
}

.right-buttons{
  margin-left: 10px;
margin-bottom: 5px;
text-align: right;
}
.right-buttons a{
  margin-left: 8px;
}

.img-preview{
  width: 50px;
  object-fit: cover;
  height: 40px;
}

.filters {
    padding: 12px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.filters  > div {
  margin-left: 10px;
}

.title-left{
  float: left;
}

.video-detail-big{
  font-size: 22px;
  font-weight: bold;
}

.img-snap img{
  display: block;
  width: calc(100%);
  height: auto;
}
.img-snap {
  margin: 5px;
}

.row-crop{
  background: #0000005e;
padding: 20px;
margin: 10px 0px;
border-radius: 2px;
}
.crop-wrapper{
  position: relative;
  height: 250px;
  margin-top: 20px;
  padding: 10px;
}
.snaps-slider{
  display: flex;
  height: 100px;
  overflow: hidden;
  align-items: center;
  justify-content: flex-end;
  background: #0f0f0f;
  padding: 0px;
}
.snaps-slider .img-snap{
  margin: 5px;

}
.snaps-slider .img-snap img{
  border-radius: 2px;
}

.snaps-slider img:hover{
  opacity: 0.6;
  cursor: pointer;
}

.img-cropped{
  float: right;
  display: block;
  border-radius: 4px;
  margin-top: 20px;
  width: 170px;
}

.tr-locked,.tr-locked:hover{
    background: #fe2c556b !important;
    /* user-select: none;
    pointer-events: none; */
}

.btn-success{
  background: green;
}

.section-title{
  float: left;
  text-transform: uppercase;
}
.section-title span{
    color: #00bcd4;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
  border-right: none !important;
}

.ant-table tbody > tr > td{
  font-size: 13px;
}

.filters > div{
    margin-bottom: 15px;
}

.message__unpaid {
  background-color: #ff2d55;
  width: 100%;
  left: 0px;
  right: 0px;
  z-index: 10;
  position: fixed;
  text-align: center;
  padding: 15px 20px;
}

.message__suspended {
  font-size: 26px;
  margin: auto;
  padding: 100px 20px;
  width: 100%;
  height: 100vh;
  text-align: center;
  max-width: 800px;
}

